.Ad {
  max-width: 100%;

  @include media(">large") {
    position: fixed;
    bottom: 1em;
    left: 1em;
    height: auto;
    z-index: 100;
  }
}

#carbonads {
  display: flex;
  max-width: 330px;
  background-color: hsl(0, 0%, 98%);
  box-shadow: 0 1px 4px 1px hsla(0, 0%, 0%, 0.1);
  margin: auto;
}

#carbonads a {
  text-decoration: none;
}

#carbonads span {
  position: relative;
  display: block;
  overflow: hidden;
}

#carbonads .carbon-wrap {
  display: flex;
}

#carbonads .carbon-img img {
  display: block;
}

#carbonads .carbon-text {
  font-size: 13px;
  padding: 10px;
  margin-bottom: 16px;
}

#carbonads .carbon-poweredby {
  padding: 6px 8px;
  background: #f1f1f2;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  font-size: 8px;
  line-height: 1;
  border-top-left-radius: 3px;
  position: absolute;
  bottom: 0;
  right: 0;
}
