/**
 * Footer
 */
.footer {
  background-color: $note-background;
  margin-top: 2rem;
  padding: 1.25rem;
  text-align: center;
}

.footer__metadata {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.footer__authors {
  font-size: 0.9em;
}
