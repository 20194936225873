/**
 * Quotes
 */
blockquote {
  background-color: $quote-background;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-style: italic;
  margin: 0 0 $vertical-rhythm-baseline;
  overflow: hidden;
  padding: $vertical-rhythm-baseline;

  /**
   * Prevent any odd gap from nested elements' margin
   */
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}
