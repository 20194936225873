/* Container used for styling the custom select, the buttom class below adds the bg gradient, corners, etc. */
.custom-select {
  position: relative;
  display: block;
  margin: 0.5em 0;
  padding: 0;
  border: .25em solid;
  color: $secondary-color;
  border-radius: 1337px;
}


/* This is the native select, we're making everything but the text invisible so we can see the button styles in the wrapper */
.custom-select select {
  width:100%;
  margin:0;
  background:none;
  border: 1px solid transparent;
  outline: none;
  /* Prefixed box-sizing rules necessary for older browsers */
  box-sizing: border-box;
  /* Remove select styling */
  appearance: none;
  -webkit-appearance: none;
  /* Font size must be 16px or larger to prevent iOS page zoom on focus */
  font-size:1em;
  /* General select styles: change as needed */
  font-family: helvetica, sans-serif;
  font-weight: bold;
  color: $secondary-color;
  padding: .6em 1.9em .5em .8em;
  line-height:1.3;
}


/* Custom arrow sits on top of the select - could be an image, SVG, icon font, etc. or the arrow could just baked into the bg image on the select. Note this si a 2x image so it will look bad in browsers that don't support background-size. In production, you'd handle this resolution switch via media query but this is a demo. */

.custom-select::after {
  content: "";
  position: absolute;
  width: 9px;
  height: 8px;
  top: 50%;
  right: 1em;
  margin-top:-4px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNQTFRFAAAAkpSXkpSXkpSXkpSXkpSXkpSXkpSXkpSXkpSXkpSXkpSXkpSXkpSXkpSXkpSXkpSXl5AZAwAAABB0Uk5TABAgMEBQYHCAj5+vv8/f7yMagooAAABRSURBVBgZXcGJFoAQFEXRQwpN7/7/1zZYQnsT0h/e9ANJow041TMPBPUSj03NwcuZPoEiqlqpDhXmqCYVkSbrsdNxpttEb5GUGe06HSOvmeICIxYIyUzm7RkAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 2;
  /* This hack make the select behind the arrow clickable in some browsers */
  pointer-events:none;
}


/* Hover style */
.custom-select:hover {
  color: $main-color;
}

/* Focus style */
.custom-select select:focus {
  outline:none;
  box-shadow: 0 0 1px 3px rgba(180,222,250, 1);
  background-color: transparent;
  color: $main-color;
  border:1px solid #aaa;
}

/* Set options to normal weight */
.custom-select option {
  font-weight:normal;
}






/* ------------------------------------  */
/* START OF UGLY BROWSER-SPECIFIC HACKS */
/* ----------------------------------  */

/* OPERA - Pre-Blink nix the custom arrow, go with a native select button to keep it simple. Targeted via this hack http://browserhacks.com/#hack-a3f166304aafed524566bc6814e1d5c7 */
x:-o-prefocus, .custom-select::after {
  display:none;
}

 /* IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance, IE 9 and earlier get a native select - targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting */
/* The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .custom-select select::-ms-expand {
    display: none;
  }
  .custom-select select:focus::-ms-value {
    background: transparent;
    color: #222;
  }
}


/* FIREFOX won't let us hide the native select arrow, so we have to make it wider than needed and clip it via overflow on the parent container. The percentage width is a fallback since FF 4+ supports calc() so we can just add a fixed amount of extra width to push the native arrow out of view. We're applying this hack across all FF versions because all the previous hacks were too fragile and complex. You might want to consider not using this hack and using the native select arrow in FF. Note this makes the menus wider than the select button because they display at the specified width and aren't clipped. Targeting hack via http://browserhacks.com/#hack-758bff81c5c32351b02e10480b5ed48e */
/* Show only the native arrow */
@-moz-document url-prefix() {
  .custom-select {
    overflow: hidden;
  }
  .custom-select select {
    width: 120%;
    width: -moz-calc(100% + 3em);
    width: calc(100% + em);
  }

}

/* Firefox focus has odd artifacts around the text, this kills that. See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */
.custom-select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}



/* ------------------------------------  */
/*  END OF UGLY BROWSER-SPECIFIC HACKS  */
/* ------------------------------------  */
